<template>
  <div>
    <div v-if="loading">
      <a-skeleton active :paragraph="{ rows: type == 'out' ? 4 : 2 }" />
    </div>
    <div v-else>
      <div class="d-flex justify-content-between align-items-center">
        <div class="pr-3">
          <h2 class="font-size-18 font-weight-bold mb-1 text-dark">
            {{ type == "in" ? $t("accueil.benefice") : $t("accueil.depense") }}
          </h2>
          <p class="font-size-15 mb-3">{{ $t("accueil.montant") }}</p>
        </div>
        <div
          v-if="type == 'in'"
          class="text-primary font-weight-bold font-size-24"
        >
          {{ money.beneficeTotal }} {{ $t("accueil.tnd") }}
        </div>
        <div v-else class="text-success font-weight-bold font-size-24">
          {{ money.depenseTotal }} {{ $t("accueil.tnd") }}
        </div>
      </div>
      <div class="mb-3">
        <a-progress
          type="line"
          :percent="percent"
          :showInfo="false"
          :strokeWidth="12"
          :strokeColor="color"
        />
      </div>
      <div class="d-flex text-gray-5 justify-content-between font-size-14">
        <span class="text-uppercase"> {{ $t("accueil.pourcentage") }}</span>
        <span class="text-uppercase">{{ percent }}%</span>
      </div>
      <a-divider v-if="type == 'out'" class="m-0 mb-2 mt-2"></a-divider>
      <div
        v-if="type == 'out'"
        class="text-gray-5 justify-content-between"
        style="font-size: 12px"
      >
        <p class="m-0 p-0">
          <b>Dépenses</b> : {{ money.depenses.depenses }}
          {{ $t("accueil.tnd") }}
        </p>

        <p class="m-0 p-0">
          <b>Salariés</b> : {{ money.depenses.employees }}
          {{ $t("accueil.tnd") }}
        </p>

        <p class="m-0 p-0">
          <b>Avoirs</b> : {{ money.depenses.avoirs }} {{ $t("accueil.tnd") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  computed: {
    percent: function () {
      const number =
        this.type == "in"
          ? (this.money.beneficeTotal /
              (this.money.beneficeTotal + this.money.depenseTotal)) *
            100
          : (this.money.depenseTotal /
              (this.money.beneficeTotal + this.money.depenseTotal)) *
            100;
      return parseInt(parseInt(number).toFixed(2));
    },
  },
  props: {
    type: {
      required: true,
    },
    color: {
      required: true,
    },
    money: {
      required: true,
    },
    loading: {
      required: true,
    },
  },
};
</script>
